import {
  LogLevel,
  PublicClientApplication,
  //   InteractionRequiredAuthError,
  BrowserAuthError,
} from "@azure/msal-browser";
import router from "./router/index.js";
import { api as feathersClient } from "./feathers.js";
import { useStore, loginStatus } from "./store/app.pinia";

const setLoginStatus = (status = loginStatus.loggedOut, clearError = false) => {
  const appStore = useStore();
  appStore.setLoginStatus(status);
  if (clearError) appStore.setAuthError(null);
};

const setLogin = (accessTokenResponse = null) => {
  const appStore = useStore();
  appStore.setLogin(accessTokenResponse);
};

const setAuth = (auth = null) => {
  const appStore = useStore();
  // Set the store
  appStore.setAuth(auth?.authentication || null);
  // Emit frame messages (if we're a frame)
  frameLogin(auth, auth.accessToken);
};

const loginRequest = {
  scopes: ["openid", "profile", "User.Read"],
};

const config = {
  auth: {
    clientId: process.env.VUE_APP_AZURE_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.VUE_APP_AZURE_DIRECTORY_ID}`,
    redirectUri: process.env.VUE_APP_AZURE_REDIRECT_URI, // Must be registered as a SPA redirectURI on your app registration
    postLogoutRedirectUri: process.env.VUE_APP_AZURE_REDIRECT_URI, // Must be registered as a SPA redirectURI on your app registration
    // navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: LogLevel.Verbose,
    },
  },
};

export const msalInstance = new PublicClientApplication(config);

const selectAccount = () => {
  const currentAccounts = msalInstance.getAllAccounts();
  // Return if there aren't any accounts
  if (currentAccounts.length === 0) {
    return null;
  }
  // Return the first account
  return currentAccounts[0];
};

export const frameLogin = (authResult, accessToken) => {
  // If we're not an iframe, return
  if (!window.parent || window === window.parent) return false;
  // Determine the requested app groups
  const appGroups = JSON.parse(router.currentRoute?.query?.appGroups ?? "[]");
  // Set up app store and error function
  const appStore = useStore();
  const setError = (message = "Not a member of this app group") => {
    appStore.setAuthError({ message });
    return false;
  };
  // If no group ids, return
  if (!Array.isArray(appGroups) || !appGroups.length) return setError();
  // Check if this member is in the requested groups (check for array intersection)
  if (!appStore.myGroups.filter((id) => appGroups.includes(id)).length)
    return setError();
  // Emit the login event
  window.parent.postMessage({ type: "ssoLogin", accessToken }, "*");
};

export const login = async () => {
  setLoginStatus(loginStatus.loading, true); // Set the login status to loading
  // Try to reauthenticate
  const reAuthResult = await feathersClient
    .reAuthenticate(false, "azure")
    .catch(() => {});
  // If the reauthentication was successful, we're done!
  if (reAuthResult) {
    setLoginStatus(loginStatus.loggedIn); // Set the login status to logged in
    return reAuthResult;
  }
  // If the reauthentication failed, we need to login
  const token = await getToken(); // Get a token from azure
  // Check if we got a token
  if (!token || !token.length) {
    setLoginStatus(loginStatus.loginError);
    return false;
  }
  let authResult;
  // Attempt to authenticate with feathers
  try {
    authResult = await feathersClient.authenticate({
      strategy: "azure",
      bearerToken: token,
    });
  } catch (e) {
    console.error("Authentication error", e);
    setLoginStatus(loginStatus.loginError);
    return false;
  }
  // Success!
  setLoginStatus(loginStatus.loggedIn);
  // Set auth result
  setAuth(authResult);
  // Resolve with the auth result
  return authResult;
};

export const loginPopup = () => {
  setLoginStatus(loginStatus.loading);
  return msalInstance
    .acquireTokenPopup(loginRequest)
    .catch((error) => {
      if (error instanceof BrowserAuthError) console.error("BrowserAuthError");
      console.error(error);
      setLogin(null);
      setLoginStatus(loginStatus.loginError);
      return null;
    })
    .then((response) => {
      if (!response) return response; // don't process empty responses
      setLogin(response);
      return response.accessToken;
    })
    .catch((error) => {
      setLogin(null);
      setLoginStatus(loginStatus.loggedOut); // Reset the login status
      console.error(error);
      return false;
    });
};

export const getToken = () => {
  setLoginStatus(loginStatus.loading);
  const account = selectAccount();
  if (account === null) {
    console.warn("No account cache found");
    return loginPopup();
  }
  return msalInstance
    .acquireTokenSilent({ ...loginRequest, account })
    .then((response) => {
      setLogin(response);
      return response.accessToken;
    })
    .catch((error) => {
      setLogin(null);
      console.log(error);
      setLoginStatus(loginStatus.loggedOut); // Reset the login status
      //   if (error instanceof InteractionRequiredAuthError) {
      //     return loginPopup();
      //   }
      return loginPopup();
    });
};
