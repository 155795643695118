import Vue from "vue";
import VueRouter from "vue-router";
import { useStore } from "@/store/app.pinia";
import AppView from "../views/AppView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "root",
    component: AppView,
    redirect: { name: "home" },
    children: [
      {
        path: "home",
        name: "home",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  {
    path: "/app-login",
    name: "app-login",
    component: () =>
      import(/* webpackChunkName: "loginframe" */ "../views/LoginView.vue"),
    props: {
      default: (route) => ({ appGroups: route.query?.appGroups ?? "" }),
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  const appStore = useStore();
  if (to?.query?.resetLogin !== undefined) {
    await appStore.logout();
  }
  // const appStore = {};
  if (!["login", "app-login"].includes(to.name) && !appStore.isLoggedIn) {
    appStore.setAfterLoginRedirect(to.fullPath.replace("resetLogin", "rl"));
    return next({ name: "login" });
  } else if (to.name === "login" && appStore.isLoggedIn) {
    appStore.afterLogin();
  }
  next();
});

export default router;
