<template>
  <v-card>
    <!-- <v-list>
      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title>Switch Module</v-list-item-title>
          <v-list-item-subtitle>Mem Module</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="logoutClick" link>
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Log Out</v-list-item-title>
          <v-list-item-subtitle>{{ username }}</v-list-item-subtitle>
          <AppLogoutDialog v-model="logoutDialog" />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider> -->

    <v-list>
      <v-list-item>
        <v-list-item-action>
          <v-switch v-model="dark" color="primary"></v-switch>
        </v-list-item-action>
        <v-list-item-title>Dark Mode</v-list-item-title>
      </v-list-item>
    </v-list>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="close"> Close </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { getCurrentInstance } from "vue";
import { computed, ref } from "vue-demi";
// import { useAuth } from "@/store/auth.pinia.js";
// import AppLogoutDialog from "./AppLogoutDialog.vue";

export default {
  name: "AppMenu",
  components: {
    // AppLogoutDialog,
  },
  setup(props, { emit }) {
    const instance = getCurrentInstance().proxy;

    const logoutDialog = ref(false);

    const close = () => {
      emit("close");
    };

    const dark = computed({
      get() {
        return instance.$vuetify?.theme?.dark;
      },
      set(value) {
        localStorage.setItem("darkMode", value);
        instance.$vuetify.theme.dark = value;
      },
    });

    const username = "test";
    // const username = computed(() => {
    //   return authStore.user?.email ?? "Logout";
    // });

    const logoutClick = () => {
      logoutDialog.value = true;
    };

    return {
      username,
      dark,
      logoutDialog,
      close,
      logoutClick,
    };
  },
};
</script>
