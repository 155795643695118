import { set } from "vue-demi";
import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import router from "../router/index.js";
import { api as feathersClient } from "../feathers.js";

const azureDefaults = {
  homeAccountId: null,
  environment: null,
  tenantId: null,
  username: null,
  localAccountId: null,
  name: null,
  idTokenClaims: {
    aud: null,
    iss: null,
    iat: null,
    nbf: null,
    exp: null,
    aio: null,
    name: null,
    nonce: null,
    oid: null,
    preferred_username: null,
    rh: null,
    sub: null,
    tid: null,
    uti: null,
    ver: null,
  },
};

const userAuth = {
  strategy: null,
  payload: {
    azureUser: {
      id: null,
      displayName: null,
      mail: null,
      userPrincipalName: null,
      memberOf: null,
      isAdmin: false,
    },
    iat: null,
    exp: null,
    aud: null,
    iss: null,
    jti: null,
  },
};

export const loginStatus = {
  loggedOut: 0,
  loading: 1,
  loggedIn: 2,
  loginError: 3,
};

export const useStore = defineStore("app", {
  state: () => ({
    loginStatus: loginStatus.loggedOut,
    azure: {
      ...azureDefaults,
    },
    userAuth: {
      ...userAuth,
    },
    afterLoginRedirect: useStorage("afterLoginRedirect", "/home"),
    azureAccessToken: null,
    authErrorMessage: null,
  }),
  getters: {
    isLoggedIn: (state) => state.loginStatus === loginStatus.loggedIn,
    isLoggingIn: (state) => state.loginStatus === loginStatus.loading,
    isLoggedOut: (state) => state.loginStatus === loginStatus.loggedOut,
    hasLoginError: (state) => state.loginStatus === loginStatus.loginError,
    feathersJwt: () => window.localStorage.getItem("feathers-jwt"),
    myGroups: (state) => [
      ...(state.userAuth?.payload?.azureUser?.memberOf?.map((i) => i.id) || []),
      ...(state.userAuth?.payload?.azureUser?.appGroups || []),
    ],
    isMemberOf: (state) => (group) =>
      state.userAuth?.payload?.azureUser?.memberOf
        ?.map((i) => i.id)
        .includes(group) || false,
    userId: (state) => state.userAuth?.payload?.azureUser?.id || null,
    isAdmin: (state) => state.userAuth?.payload?.azureUser?.isAdmin || false,
  },
  actions: {
    setAfterLoginRedirect(redirect = "/home") {
      this.afterLoginRedirect = redirect;
    },
    setAccount(account) {
      // Set the account
      set(this.azure, account !== null ? account : { ...azureDefaults });
    },
    setAuth(auth) {
      // Set the auth
      set(this.userAuth, "payload", auth?.payload ?? { ...userAuth.payload });
      // Update the login status
      this.setLoginStatus(
        this.userId?.length ? loginStatus.loggedIn : loginStatus.loggedOut
      );
      return this.afterLogin();
    },
    setAuthError(error) {
      this.authErrorMessage = error?.message ?? null;
    },
    setAccessToken(accessToken = null) {
      this.azureAccessToken = accessToken;
    },
    setLogin(accessTokenResponse = null) {
      if (accessTokenResponse === null) {
        this.setLoginStatus(loginStatus.loggedOut);
        this.setAccount(null);
        this.setAccessToken(null);
        return;
      }
      if (
        accessTokenResponse !== null &&
        accessTokenResponse?.accessToken?.length
      ) {
        this.setAccount(accessTokenResponse.account);
        this.setAccessToken(accessTokenResponse.accessToken);
        this.setLoginStatus(loginStatus.loggedIn);
      }
    },
    setLoginStatus(status = null) {
      this.loginStatus = status !== null ? status : loginStatus.loggedOut;
    },
    setLoginLoading() {
      this.setLoginStatus(loginStatus.loading);
    },
    afterLogin() {
      if (this.isLoggedIn && router.currentRoute.name === "login") {
        router.push(this.afterLoginRedirect).catch(() => {});
      }
    },
    async logout() {
      await feathersClient.logout();
      this.setLogin(null);
      this.setAccount(null);
      this.setAccessToken(null);
      return true;
    },
  },
});
