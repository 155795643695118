import Vue from "vue";
import { createPinia, PiniaVuePlugin } from "pinia";
import { setupFeathersPinia } from "feathers-pinia";
import { api } from "../feathers";

Vue.use(PiniaVuePlugin);
export const pinia = createPinia();
Vue.use(pinia);

export const { defineStore, BaseModel } = setupFeathersPinia({
  clients: { api },
  idField: "id",
});
