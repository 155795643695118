<template>
  <v-container>
    <AppBar />
    <v-main>
      <router-view />
    </v-main>
  </v-container>
</template>

<script>
import AppBar from "../components/AppBar.vue";

export default {
  name: "AppView",

  components: {
    AppBar,
  },
};
</script>
