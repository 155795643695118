import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
// import i18n from "./i18n.js";

Vue.use(Vuetify);

export default new Vuetify({
  // lang: i18n(),
  theme: {
    dark: ["true", true].includes(localStorage.getItem("darkMode")),
  },
});
