<template>
  <v-app-bar
    app
    color="primary"
    class="v-bar--underline"
    dark
    v-model="isDisplayed"
    transition="slide-x-transition"
  >
    <div class="logo-area d-flex align-center justify-space-around">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="150"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon x-large>
            <v-icon size="50">mdi-apps</v-icon>
          </v-btn>
        </template>
        <AppMenu @close="menu = false" />
      </v-menu>
      <div class="d-flex align-center logo" @click="() => (menu = true)">
        <v-img
          src="../assets/logo.png"
          alt="CP Hub Logo"
          max-width="52"
          contain
        />
        <h1 class="font-heebo logo-text px-0 pb-0 ma-0">GRANITE</h1>
      </div>
    </div>

    <!-- <AppBarEnv /> -->
    <v-spacer></v-spacer>
    <v-spacer></v-spacer>
    <!-- <AzureLogin /> -->
  </v-app-bar>
</template>

<script>
import { ref } from "vue-demi";
import AppMenu from "@/components/AppMenu.vue";

export default {
  name: "AppBar",
  components: {
    AppMenu,
  },
  setup() {
    const isDisplayed = ref(true);
    const menu = ref(false);

    return {
      menu,
      isDisplayed,
    };
  },
};
</script>

<style lang="scss">
.v-toolbar__extension {
  background-color: rgba(0, 0, 0, 0.15);
}

.logo-area {
  .logo {
    cursor: pointer;

    .logo-text {
      padding-top: 3px;
    }
  }
}

.logo-text {
  font-size: 3.5rem;
  line-height: 3rem;
  user-select: none;
}
</style>
