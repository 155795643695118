<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import { login } from "./authentication.js";

export default {
  name: "App",
  setup() {
    // Trigger login
    login();

    // @TODO: watch for logout here?
    return {};
  },
};
</script>

<style>
.font-heebo {
  font-family: "Heebo", sans-serif;
}
</style>
